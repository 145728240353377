<template>
  <div class="login">
    <v-container fluid>
      <Navbar></Navbar>
      <div class="form">
        <v-row color="primary"> 
          <v-col :cols=1></v-col>
          <v-col :cols=11>
            <h1 class="primary--text">My Camp Information</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="8">
            <BasicCampInfo v-if="!edit" :camp="camp" @edit="edit=true"></BasicCampInfo>
            <EditCampForm v-else :camp="campEdited" :categories="categories" @close="edit = false" @save="SaveCamp(camp)"></EditCampForm>
          </v-col>
          <v-col :cols="4">
            <v-card class="campSessionInfo--card">
              <div class="campSessionInfo--card">
                <img class="campSessionInfo--img" 
                  :src="campImage"
                  alt="Camp Image"
                  height=300
                />
                <v-btn @click="OpenUploadImg" class="mr-5 mt-3 mb-3"  color="primary">Upload</v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col :cols=12>
            <v-card>
              <v-card-title>Camp Categories</v-card-title>
                <v-card-text class="d-flex">
                  <v-combobox
                    v-model="campCategories"
                    :items="categories"
                    chips
                    clearable
                    label="Session Categories"
                    multiple
                    solo
                    :disabled="!edit"
                  >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="remove(item)"
                    >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>Camp Sessions Offered: </v-card-title>
              <v-card-text class="d-flex">
                <v-combobox
                  v-model="campOperateDuring"
                  :items="sessionTimes"
                  chips
                  clearable
                  label=""
                  multiple
                  solo
                  :disabled="!edit"
                >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeTime(item)"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>Camp Description/Mission Statement</v-card-title>
              <v-card-text class="d-flex" v-if="!edit">
                {{camp.description}}
              </v-card-text>
              <v-textarea v-if="edit"
                outlined
                label="Camp Desciption/Mission Statement"
                v-model="camp.description"
              ></v-textarea>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="edit">
          <v-col>
            <v-btn @click="SaveCamp(camp)" class="mr-2" color="primary">Save</v-btn>
            <v-btn @click="Close" color="primary">Cancel</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-if="imgUploading"
      v-model="imgUploading"
      width="800"
    >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Upload Camp Image
      </v-card-title>
      <v-card-text>
        <v-progress-circular
          v-if="uploadInProgress"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-file-input
          v-model="campImg"
          label="Click to Choose File"
        ></v-file-input>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="UploadImg"
          >
            Upload
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="imgUploading = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Navbar from '../components/Navbar.vue';
import BasicCampInfo from '../components/CampDashboard/BasicCampInfo.vue';
import EditCampForm from '../components/CampDashboard/EditCampForm.vue';
import CampService from '@/api-services/camp.service.js'
import AuthService from '@/api-services/auth.service.js'
import CategoriesService from '@/api-services/categories.service.js';
import AzureService from '@/api-services/azure.service.js';
import CampImages from '@/api-services/campImages.service.js';

export default {
  name: 'ManageCamp',
  components: {
    'Navbar': Navbar,
    'EditCampForm': EditCampForm,
    'BasicCampInfo': BasicCampInfo
  },
  data () {
    return {
      categories: this.$store.state.categories,
      campCategories: [],
      edit: false,
      sessionTimes: ["Summer", "Fall Break", "Spring Break", "School Holidays"],
      camp: {
        desc: "This is the description.",
        overnightPrograms: false,
        dayPrograms: true,
        beforeCare: true,
        afterCare: true,
        operateSummer: false,
        operateFallbreak: false,
        operateSpringbreak: false,
        operateSchoolHolidays: false,
     },
     campImage: "",
     imgUploading: false,
     uploadInProgress: false,
     campImg: null,
     campEdited: {},
     localCamp: {},
     campImageExists: false
    }
  },
  computed: {
    campOperateDuring: {
      get() {
        if(this.camp.operateDuring && this.camp.operateDuring !== ''){
          return this.camp.operateDuring.split(',')
        } else {
          return ["summer"]
        }
      },
      set(newVal) {
        let string = newVal.toString();
        this.camp.operateDuring = string
      }
    }
  },
  async mounted () {
    let campId = this.$store.state.campId? this.$store.state.campId : -1
    if(campId === -1) {
      const user = await AuthService.getUser()
      campId = user.data.campId
    }
    await CampService.getCamp(campId)
    .then(async (response) =>  {
      this.camp = response.data
      this.campEdited = response.data
      this.camp.id = campId
      this.$store.commit('updateCamp', response.data)
      this.campCategories = response.data.categories? response.data.categories.split(',') : []
      await this.GetImage()
    })
    if(this.$store.state.categories.length < 1) {
      CategoriesService.getCategories()
      .then((response) => {
        let categories = []
        response.data.forEach(cat => {
          categories.push(cat.categoryText)
        });
        this.categories = categories
        this.$store.commit('updateCategories', categories)
      })
    }
  },
  methods: {
    OpenUploadImg(){
      this.imgUploading = true
    },
    async UploadImg() {
      this.uploadInProgress = true
      const imageKey =`${this.camp.id}-${this.campImg.name}`
      const bucket = 'campimages'
      let image = {
        "Id": this.camp.id,
        "Bucket": bucket,
        "BucketKey": imageKey
      }
      let url = await AzureService.GetPresignedPutUrl('campimages', imageKey)
      await AzureService.PutImageToAzureContainer(url.data, this.campImg)
      await CampImages.updateCampImage(image)
      this.camp.bucketKey = this.campImg.name
      this.uploadInProgress = false
      await this.GetImage()
      this.imgUploading = false
    },
    async GetImage() {
      let url
      if(this.camp.bucketKey) {
          url = await AzureService.GetImageUrl("campimages", `${this.camp.bucketKey}`)
      }
      this.campImage = this.camp.bucketKey ? url.data : "/NoCampImage.jpg"
    },
     remove(item) {
      let catToRemove = this.campCategories.findIndex(el => el === item.categoryText)
      if(catToRemove === -1) {
        catToRemove = this.campCategories.findIndex(el => el === item)
      }
      this.campCategories.splice(catToRemove,1)
    },
    removeTime(item) {
      let campOperateDuring = this.campOperateDuring.findIndex(el => el === item)
      this.campOperateDuring.splice(campOperateDuring,1)
    },
    Close() {
      this.camp = this.$store.state.camp
      this.edit = false
    },
    async SaveCamp(camp) {
      camp.description = this.campEdited.description
      camp.overnightPrograms = this.campEdited.overnightPrograms ? 1 : 0
      camp.beforeCare = this.campEdited.beforeCare ? 1 : 0
      camp.afterCare = this.campEdited.afterCare ? 1 : 0
      camp.dayPrograms = this.campEdited.dayPrograms ? 1 : 0
      camp.operateDuring = this.campOperateDuring.toString()
      camp.priceHigh = parseInt(this.campEdited.priceHigh)
      camp.priceLow = parseInt(this.campEdited.priceLow)
      camp.ageOldest = parseInt(this.campEdited.ageOldest)
      camp.ageYoungest = parseInt(this.campEdited.ageYoungest)
      camp.meals = this.campEdited.meals? 1 : 0
      camp.Categories = this.campCategories.toString()
      this.camp = camp
      await CampService.updateCamp(this.camp)
      this.edit = false
    },
    AddTime(time) {
      this.campOperateDuring.push(time)
    }
  },
  watch: {
    edit(val) {
      if(val){
        this.localCamp = this.camp
      }
    }
  }
}
</script>
<style scoped>

</style>
