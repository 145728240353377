<template>
  <v-card class="campSessionInfo--card">
    <v-card-title>Basic Info
      <v-icon depressed @click="$emit('edit')">mdi-pencil</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols=6>
          <div class="text-left">
            <span class="font-weight-bold">Name:</span>
            {{camp.campName}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">email:</span>
            {{camp.email}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">Phone:</span>
            {{camp.phone}}
          </div>
          
          <div class="text-left">
            <span class="font-weight-bold">Alternate email:</span>
            {{camp.altEmail}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">Alternate Phone:</span>
            {{camp.altPhone}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">Camp Start Time:</span>
            {{camp.campStartTime}}
          </div>
         
        </v-col>
        <v-col :cols=6>
          <div class="text-left">
            <span class="font-weight-bold">url:</span>
            {{camp.url}}
          </div>       
          <div class="text-left">
            <span class="font-weight-bold">Address Line 1:</span>
            {{camp.addressLine1}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">Address Line 2:</span>
            {{camp.addressLine2}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">City:</span>
            {{camp.city}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">State:</span>
            {{camp.state}}
          </div>
          <div class="text-left">
            <span class="font-weight-bold">Zipcode:</span>
            {{camp.zipcode}}
          </div>
           <div class="text-left">
            <span class="font-weight-bold">Camp End Time:</span>
            {{camp.campEndTime}}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="6">
           <div class="text-left">
            <span class="font-weight-bold">Price Range:</span>
            ${{camp.priceLow}} - ${{camp.priceHigh}}
          </div>
        </v-col>
        <v-col :cols="6">
           <div class="text-left">
            <span class="font-weight-bold">Age Range:</span>
            {{camp.ageYoungest}} - {{camp.ageOldest}}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="6">
          <v-chip
            class="custom--chip"
            :color="camp.beforeCare ? 'primary' : 'red'"
          >
            <span v-if="!camp.beforeCare" class="mr-1">No</span>
            Before Care
          </v-chip>
          <div v-if="camp.beforeCare" class="text-left">
            <span class="font-weight-bold">Before Care Start Time:</span>
            {{camp.beforeCareStart}}
          </div>
          </v-col>
          <v-col :cols="6">
            <v-chip
              class="custom--chip"
              :color="camp.afterCare ? 'primary' : 'red'"
            >
              <span v-if="!camp.afterCare" class="mr-1">No</span>
              After Care
            </v-chip>
          <div v-if="camp.afterCare" class="text-left">
            <span class="font-weight-bold"> After Care End Time:</span>
              {{camp.afterCareEnd}}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12" class="flex">
          <v-chip
              class="custom--chip"
              :color="camp.dayPrograms ? 'primary' : 'red'"
            >
            <span v-if="!camp.dayPrograms" class="mr-1">No</span>
              Day Programs 
            </v-chip>
           <v-chip
              class="custom--chip"
              :color="camp.overnightPrograms ? 'primary' : 'red'"
            >
              <span v-if="!camp.overnightPrograms" class="mr-1">No</span>
              Overnight Programs 
            </v-chip>
          <v-chip
              class="custom--chip"
              :color="camp.meals ? 'primary' : 'red'"
            >
              <span v-if="!camp.meals" class="mr-1">No</span>
              Meals Served
            </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>


export default {
  name: 'BasicCampInfo',
  props: ['camp'],
  components: {
  },
  data () {
    return {
     
    }
  },
  async mounted () {
  
  },
  methods: {
    
  },
  
}
</script>
<style scoped>
  .col--no-padding {
    padding: 0 12px;
  }

  .custom--chip {
    display: flex;
    width: fit-content;
    margin-right: 10px;
    margin-top: 5px;
  }
  .red {
    color: #fff;
  }

  .flex {
    display: flex;
  }
</style>
