<template>
<v-card  class="campSessionInfo--card">
  <v-card-title>Edit Info
      <v-icon depressed @click="Close">mdi-close</v-icon>
      <v-icon depressed @click="SaveCamp">mdi-content-save</v-icon>
  </v-card-title>
  <v-card-text >
    <v-row>
      <v-col :cols=6>
        <div class="text-left">
          <v-text-field v-model="camp.campName" label="Name" :value="camp.name" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.email" label="email" :value="camp.email" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.phone" label="Phone" :value="camp.phone" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.altEmail" label="Alternate email" :value="camp.altEmail" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.altPhone" label="Alternate Phone" :value="camp.altPhone" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.campStartTime" label="Camp Start Time" :value="camp.campStartTime" type="time" />
        </div>
      </v-col>
      <v-col :cols=6>
        <div class="text-left">
          <v-text-field v-model="camp.url" label="url" :value="camp.url" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.addressLine1" label="Address Line 1" :value="camp.addressLine1" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.addressLine2" label="Address Line 2" :value="camp.addressLine2" />
        </div>
        <div class="text-left">
          <v-text-field v-model="camp.city" label="City" :value="camp.city" />
        </div>
        <v-row>
          <v-col :cols=4>
            <div class="text-left">
              <v-autocomplete
                v-model="camp.state"
                :items="states"
                dense
                label="State"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col :cols=8>
            <div class="text-left">
              <v-text-field v-model="camp.zipcode" dense label="Zipcode" :value="camp.zipcode" />
            </div>
          </v-col>
        </v-row>
        <div class="text-left">
          <v-text-field v-model="camp.campEndTime" label="Camp End Time" :value="camp.campEndTime" type="time" />
        </div>       
      </v-col>
    </v-row>
    <v-row>
        <v-col :cols="3">
           <div class="text-left">
           <v-text-field v-model="camp.priceLow" label="Price Low($)" :value="camp.priceLow" type="number" />
          </div>
        </v-col>
        <v-col :cols="3">
           <div class="text-left">
           <v-text-field v-model="camp.priceHigh" label="Price High($)" :value="camp.priceHigh" type="number" />
          </div>
        </v-col>
        <v-col :cols="3">
           <div class="text-left">
             <v-text-field v-model="camp.ageYoungest" label="Youngest Age" :value="camp.ageYoungest" type="number" />
          </div>
        </v-col>
        <v-col :cols="3">
           <div class="text-left">
             <v-text-field v-model="camp.ageOldest" label="Oldest Age" :value="camp.ageOldest" type="number" />
          </div>
        </v-col>
      </v-row>
    <v-row>
      <v-col :cols="6">
        <v-checkbox v-model="camp.beforeCare"  label="Before Care Offered"></v-checkbox>
        <v-text-field v-if="camp.beforeCare" v-model="camp.beforeCareStart" label="Before Care Start Time" :value="camp.beforeCareStart" type="time"/>
      </v-col>
      <v-col :cols="6">
        <v-checkbox v-model="camp.afterCare"  label="After Care Offered"></v-checkbox>
        <v-text-field v-if="camp.afterCare" v-model="camp.afterCareEnd" label="After Care End Time" :value="camp.afterCareEnd" type="time"/>
      </v-col>
      </v-row>
      <v-row>
      <v-col :cols="4">
         <v-checkbox v-model="camp.dayPrograms"  label="Day Programs Offered?"></v-checkbox>
      </v-col>
      <v-col :cols="4">
        <v-checkbox v-model="camp.overnightPrograms"  label="Overnight Programs Offered?"></v-checkbox>
      </v-col>
      <v-col :cols="4">
        <v-checkbox v-model="camp.meals"  label="Meals Served?"></v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>

<script>


export default {
  name: 'EditCampForm',
  props: ['camp', 'categories'],
  data () {
    return {
      states: ['MN', 'TN', 'KY']
    }
  },
  
  methods: {
    Close() {
      this.$emit('close')
    },
    SaveCamp() {
      this.$emit('save', this.localCamp)
    }
  },
}
</script>
<style scoped>

</style>
